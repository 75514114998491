/* eslint-disable react/no-danger */
import React from "react";
import cn from "classnames";
import { useWindowDimensions } from "~hooks";
import { BlockContent, Grid } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * TextArticle component.
 *
 * @return {node}
 */
const TextArticle = ({
  data: {
    backgroundColor,
    fontColor,
    anchorId,
    title,
    borderTop,
    _rawBody: rawBody
  }
}) => {
  const { isMobile } = useWindowDimensions();

  if (anchorId) {
    anchorId = anchorId.replace(`#`, ``);
  }

  return (
    <section
      id={anchorId || ``}
      className={cn(styles.container, { [styles.borderTop]: borderTop })}
      style={{
        background: backgroundColor?.hex || `#33FF33`,
        color: fontColor?.hex || `black`
      }}
    >
      <Grid>
        <header className={styles.title}>
          <h2 className={isMobile ? `b2` : `caption`}>{title}</h2>
        </header>

        <div className={styles.body}>
          {rawBody && <BlockContent blocks={rawBody} />}
        </div>
      </Grid>
    </section>
  );
};

export default TextArticle;
