/* eslint-disable import/no-extraneous-dependencies */

import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Fog } from "~components";

import * as styles from "./styles.module.scss";

const SmokeMachine = ({
  background,
  dimensions = null,
  num,
  shouldRender = true
}) => (
  <>
    {shouldRender && (
      <div className={styles.canvas}>
        <Canvas camera={{ zoom: 1, position: [0, 0, 500] }}>
          <Suspense fallback={null}>
            <Fog background={background} dimensions={dimensions} num={num} />
          </Suspense>
        </Canvas>
      </div>
    )}
  </>
);

export default SmokeMachine;
