// extracted by mini-css-extract-plugin
export var body = "styles-module--body--f7d97";
export var borderTop = "styles-module--borderTop--3b00c";
export var button = "styles-module--button--a3d1a";
export var buttonContainer = "styles-module--buttonContainer--eff56";
export var buttonIcon = "styles-module--buttonIcon--7ffa3";
export var container = "styles-module--container--ff525";
export var desktop = "1340px";
export var expandableBody = "styles-module--expandableBody--49b35";
export var expandableBodyFixed = "styles-module--expandableBodyFixed--05828";
export var expandableBodyHidden = "styles-module--expandableBodyHidden--244f9";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--b8e56";