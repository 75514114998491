/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { useApp, useInterval, useWindowDimensions } from "~hooks";
import { HTMLVideo, Image, SmokeMachine } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Global header component
 *
 * @return {node}
 */
const Banner = ({
  data: { smokeKey, videoUrl, image, imageXS, subheading, heading, bodyString }
}) => {
  // ---------------------------------------------------------------------------
  // hooks

  const { menuActive } = useApp();
  const { isDesktop, windowSize } = useWindowDimensions();

  if (subheading && subheading?.includes(`---`)) {
    subheading = subheading.replace(
      `---`,
      `<span class="${styles.bigline}"></span>`
    );
  }

  // ---------------------------------------------------------------------------
  // context / ref / state

  const ref = useRef();

  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0
  });
  const [glitchActive, setGlitchActive] = useState(false);
  const [glitchSkewed, setGlitchSkewed] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  useInterval(() => {
    setGlitchActive(Math.random() < 0.5);
  }, 200);

  useInterval(() => {
    setGlitchSkewed(Math.random().toFixed(2) * 10);
  }, 500);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const { width, height } = ref.current.getBoundingClientRect();

    setContainerDimensions({ width, height });
  }, [ref, windowSize]);

  // ---------------------------------------------------------------------------
  // render

  const innerMarqueeJSX = (
    <>
      {subheading && (
        <div className={styles.marquee}>
          <div className={styles.marqueeRow}>
            {Array(20)
              .fill(null)
              .map(() => (
                <div
                  className={cn(
                    styles.marqueeRowText,
                    styles.marqueeRowTextPadded,
                    `h1`
                  )}
                  dangerouslySetInnerHTML={{ __html: subheading }}
                />
              ))}
          </div>
        </div>
      )}

      {heading && (
        <div className={styles.marquee}>
          <div className={styles.marqueeRow}>
            {Array(20)
              .fill(null)
              .map(() => (
                <div className={styles.marqueeRowText}>
                  <h2 className="h1">{heading}&nbsp;·&nbsp;</h2>
                </div>
              ))}
          </div>
        </div>
      )}

      {bodyString && (
        <div className={styles.marquee}>
          <div className={styles.marqueeRow}>
            {Array(20)
              .fill(null)
              .map(() => (
                <div className={styles.marqueeRowText}>
                  <h2 className="b2">
                    {bodyString}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </h2>
                </div>
              ))}
          </div>
        </div>
      )}
    </>
  );

  const marqueeJSX = (
    <div className={styles.marqueeContainer}>{innerMarqueeJSX}</div>
  );

  const marqueeGlitchJSX = (
    <div
      className={cn(styles.marqueeContainer, styles.marqueeContainerGlitch, {
        [styles.marqueeContainerGlitchActive]: glitchActive
          ? styles.marqueeContainerGlitchActive
          : null,
        [styles.marqueeContainerGlitchSkewed]: glitchSkewed
          ? styles.marqueeContainerGlitchSkewed
          : null
      })}
      style={{
        transform: `translate3d(${glitchSkewed}rem, 0, 0)`
      }}
    >
      {innerMarqueeJSX}
    </div>
  );

  //

  return (
    <section ref={ref} className={styles.container}>
      {(videoUrl && (
        <div className={styles.media}>
          <HTMLVideo
            className={styles.video}
            autoPlay
            muted
            loop
            src={videoUrl}
          />
        </div>
      )) || (
        <>
          <figure className={styles.media}>
            {(!isDesktop && imageXS && (
              <Image image={imageXS} className={styles.image} />
            )) || <Image image={image} className={styles.image} />}
          </figure>

          {/* {(smokeKey && (
            <div className={styles.media}>
              <SmokeMachine
                background={`/webgl/fog/${smokeKey}.jpg`}
                dimensions={containerDimensions}
                num={100}
                shouldRender={!menuActive}
              />
            </div>
          )) || (
            <figure className={styles.media}>
              {(!isDesktop && imageXS && (
                <Image image={imageXS} className={styles.image} />
              )) || <Image image={image} className={styles.image} />}
            </figure>
          )} */}
        </>
      )}

      {marqueeJSX}
      {marqueeGlitchJSX}
    </section>
  );
};

export default Banner;
