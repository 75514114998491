import React, { useEffect } from "react";
import cn from "classnames";
import { useApp, useKeyPress, useSanitySettings } from "~hooks";
import { Button, Grid } from "~components";
import { ReactComponent as X } from "~assets/svg/x-circle-thin.svg";

import * as styles from "./styles.module.scss";

const DEFAULT_ACKNOWLEDGEMENT = `Yirramboi respectfully acknowledges the Traditional Owners of the land, the Wurundjeri Woi Wurrung and Bunurong Boon Wurrung peoples of the Eastern Kulin and pays respect to their Elders past, present and emerging.`;

/** ============================================================================
 * @component
 * Global header component
 *
 * @return {node}
 */
const Acknowledgement = () => {
  // ---------------------------------------------------------------------------
  // hooks

  const { sanitySettings } = useSanitySettings();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const { setAcknowledged, acknowledgementActive, setAcknowledgementActive } =
    useApp();

  const escKeyPressed = useKeyPress(`Escape`);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (escKeyPressed) {
      setAcknowledgementActive(false);
    }
  }, [escKeyPressed]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div
      className={cn(styles.container, {
        [styles.containerActive]: acknowledgementActive
          ? styles.containerActive
          : null
      })}
    >
      <div className={styles.closeButton}>
        <button type="button" onClick={() => setAcknowledgementActive(false)}>
          <X />
        </button>
      </div>

      <Grid>
        <div className={styles.message}>
          <p className="h3">
            {sanitySettings?.acknowledgement !== ``
              ? sanitySettings.acknowledgement
              : DEFAULT_ACKNOWLEDGEMENT}
          </p>

          <Button
            className={styles.button}
            color="black"
            onClick={() => {
              setAcknowledged(true);
              setAcknowledgementActive(false);
            }}
            transparent
          >
            <span className="button-text">ENTER FESTIVAL</span>
          </Button>
        </div>
      </Grid>
    </div>
  );
};

export default Acknowledgement;
