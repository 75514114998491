/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import cn from "classnames";
import { Grid } from "~components";
import * as styles from "./styles.module.scss";

const BrandGenerator = ({ relative = false }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const description = `How do you share what it is that
you do? How do you tell the world about
it in a way that feels like you? Easy.
Start somewhere, anywhere. And keep clicking until you find... you.

Genome uses AI to guide self
expression. We know brand, you know
you - so whatever you do, you're just a
few clicks away...`;

  // ---------------------------------------------------------------------------
  // render

  return (
    <section
      className={cn(styles.container, {
        [styles.containerRelative]: relative ? styles.containerRelative : null
      })}
    >
      <Grid className={cn(styles.grid)}>
        <article className={cn(styles.content)}>
          <h2 className="h1">
            My florist brand called Okay, Broomer is no BS, spunky and
            straight-up.
          </h2>
        </article>

        <article className={cn(styles.brand)}>
          <div className={cn(styles.brandCard)}>
            <></>
          </div>
        </article>
      </Grid>
    </section>
  );
};

export default BrandGenerator;
