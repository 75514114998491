import React from "react";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * PageHeading component.
 *
 * @return {node}
 */
const PageHeading = ({ data: { backgroundColor, fontColor, heading } }) => (
  <section
    className={styles.container}
    style={{
      background: backgroundColor?.hex || `transparent`,
      color: fontColor?.hex || `black`
    }}
  >
    <h1 className="h1">{heading}</h1>
  </section>
);

export default PageHeading;
