// extracted by mini-css-extract-plugin
export var cardContainer = "styles-module--cardContainer--c8533";
export var container = "styles-module--container--74685";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var tag = "styles-module--tag--a71c2";
export var tagClear = "styles-module--tagClear--ced7a";
export var tagClearButton = "styles-module--tagClearButton--730b5";
export var tagClearIcon = "styles-module--tagClearIcon--9536b";
export var tagFlex = "styles-module--tagFlex--bbf4a";
export var tagSelect = "styles-module--tagSelect--665bc";
export var tagSelectArrow = "styles-module--tagSelectArrow--5fa6b";
export var tagSelectContent = "styles-module--tagSelectContent--df263";
export var tagSelectExpand = "styles-module--tagSelectExpand--3876d";
export var tagSelectExpanded = "styles-module--tagSelectExpanded--962b7";
export var tagSelectOption = "styles-module--tagSelectOption--c9933";
export var tagSelectOptionActive = "styles-module--tagSelectOptionActive--1e566";
export var tags = "styles-module--tags--ec223";