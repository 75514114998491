import { useEffect } from "react";
import { create } from "zustand";
import { usePathname } from "~hooks";
import { isBrowser } from "~utils/helpers";

const validPathnames = [`/contact`, `/contact/`, `/program`, `/program/`];

function getStyle(pathname) {
  if (!isBrowser) {
    return null;
  }

  return validPathnames.includes(pathname) ? `black` : `green`;
}

const useHeaderStore = create((set) => ({
  style:
    typeof window === `undefined` ? null : getStyle(window.location.pathname),
  setStyle: (pathname) => {
    set({
      style: getStyle(pathname)
    });
  }
}));

export const useHeaderStyle = () => {
  const { style, setStyle } = useHeaderStore((state) => state);
  const pathname = usePathname();

  useEffect(() => {
    setStyle(pathname);
  }, [pathname, setStyle]);

  return style;
};

export default useHeaderStore;
