/* eslint-disable react/button-has-type */

import React, { useEffect, useRef, useState } from "react";
import { useWindowDimensions } from "~hooks";

const HEADER_OFFSETS = {
  desktop: 89,
  mobile: 73
};

const AnchorLink = ({ children, href }) => {
  // ---------------------------------------------------------------------------
  // hooks

  const { isDesktop, screen } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const anchorLinkRef = useRef();
  const [headerHeight, setHeaderHeight] = useState(null);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!screen) {
      return;
    }

    setHeaderHeight(isDesktop ? HEADER_OFFSETS.desktop : HEADER_OFFSETS.mobile);
  }, [screen, isDesktop]);

  useEffect(() => {
    if (!anchorLinkRef?.current || headerHeight === null) {
      return () => {};
    }

    const link = anchorLinkRef?.current;

    const adjustedScroll = (event) => {
      event.preventDefault();

      const targetId = link.getAttribute(`href`);
      const target = document.getElementById(targetId.substring(1));

      if (!target) {
        return;
      }

      const targetPosition =
        target.getBoundingClientRect().top + window.pageYOffset;
      const offsetPosition = targetPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: `smooth`
      });
    };

    link.addEventListener(`click`, adjustedScroll, false);

    return () => {
      link.removeEventListener(`click`, adjustedScroll, false);
    };
  }, [anchorLinkRef, headerHeight]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <a ref={anchorLinkRef} href={href}>
      {children}
    </a>
  );
};

export default AnchorLink;
