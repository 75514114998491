/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const ReleaseScheduleCard = ({ data: { heading, body, tags } }) => (
  <article className={cn(styles.container)}>
    <header className={cn(styles.header)}>
      <h3 className="b2">{heading}</h3>

      <ul className={cn(styles.tags)}>
        {tags.map(({ label, color, live }) => (
          <li
            key={`card-tag-${heading}-${label}`}
            className={cn(styles.tag, {
              [styles.tagGrey]: color === `grey`,
              [styles.tagLilac]: color === `lilac`
            })}
          >
            <span className="caption">
              {live ? `• ` : ``}
              {label}
            </span>
          </li>
        ))}
      </ul>
    </header>

    <p className="h3">{body}</p>
  </article>
);

export default ReleaseScheduleCard;
