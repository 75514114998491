// extracted by mini-css-extract-plugin
export var cardContainer = "styles-module--cardContainer--04552";
export var container = "styles-module--container--e139e";
export var desktop = "1340px";
export var giant = "2200px";
export var gridHeading = "styles-module--gridHeading--d44d4";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";