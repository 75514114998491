/* eslint-disable react/button-has-type */

import React, { useState } from "react";
import cn from "classnames";
import { Image } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Card component, used for Events.
 *
 * @param {string} props.buttonTarget
 * @param {string} props.buttonText
 * @param {Object} props.data
 * @param {Object} props.data.cardImage
 * @param {string} props.data.title
 * @param {string} props.data.subtitle
 * @param {Array} props.data.tags
 *
 * @return {JSX.Element} Card component.
 */
const GlitchImage = ({ active, image }) => {
  const [animationDurations, setAnimationDurations] = useState([]);

  // ---------------------------------------------------------------------------
  // methods

  const handleAnimationStart = () => {
    setAnimationDurations([
      `${Math.floor(Math.random() * (30 - 10 + 1) + 20) / 10}s`,
      `${Math.floor(Math.random() * (30 - 10 + 1) + 20) / 10}s`,
      `${Math.floor(Math.random() * (30 - 10 + 1) + 20) / 10}s`
    ]);
  };

  // ---------------------------------------------------------------------------
  // render

  return (
    <div
      className={cn(styles.container, {
        [styles.containerActive]: active ? styles.containerActive : null
      })}
    >
      <figure>
        <Image className={styles.image} image={image} />
      </figure>

      <div
        className={cn(styles.glitch, styles.glitch1)}
        style={{
          "--animation-duration-1": animationDurations[0],
          background: `url(${image.asset.gatsbyImageData.images.fallback.src}) no-repeat center center`,
          backgroundSize: `cover`
        }}
        onAnimationStart={handleAnimationStart}
      >
        <div className={cn(styles.glitchOverlay)} />
      </div>

      <div
        className={cn(styles.glitch, styles.glitch2)}
        style={{
          "--animation-duration-2": animationDurations[1],
          background: `url(${image.asset.gatsbyImageData.images.fallback.src}) no-repeat center center`,
          backgroundSize: `cover`
        }}
        onAnimationStart={handleAnimationStart}
      >
        <div className={cn(styles.glitchOverlay)} />
      </div>

      <div
        className={cn(styles.glitch, styles.glitch3)}
        style={{
          "--animation-duration-3": animationDurations[2],
          background: `url(${image.asset.gatsbyImageData.images.fallback.src}) no-repeat center center`,
          backgroundSize: `cover`
        }}
        onAnimationStart={handleAnimationStart}
      >
        <div className={cn(styles.glitchOverlay)} />
      </div>
    </div>
  );
};

export default GlitchImage;
