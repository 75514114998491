/* eslint-disable react/button-has-type */
import React from "react";
import { AnchorLink, Button, Grid, Link } from "~components";

import * as styles from "./styles.module.scss";

const LinkFlex = ({ data: { backgroundColor, fontColor, links } }) => (
  <section
    className={styles.container}
    style={{
      background: backgroundColor?.hex || `#33FF33`,
      color: fontColor?.hex || `black`
    }}
  >
    <Grid>
      <ul className={styles.buttons}>
        {links?.[0] &&
          links.map(({ text, url }) => {
            const isAnchor = url?.startsWith(`#`);

            const buttonJSX = (
              <Button color="white" transparent>
                <span className="button-text">{text}</span>
              </Button>
            );

            return (
              <li key={`link-flex-${text}`}>
                {(isAnchor && (
                  <AnchorLink href={url}>{buttonJSX}</AnchorLink>
                )) || <Link to={url}>{buttonJSX}</Link>}
              </li>
            );
          })}
      </ul>
    </Grid>
  </section>
);

export default LinkFlex;
