/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

export const AppContext = createContext({});

/** ============================================================================
 * @context
 * Global application data.
 */
const AppProvider = ({ children }) => {
  // ---------------------------------------------------------------------------
  // hooks

  const [cookies, setCookie] = useCookies(null);

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [acknowledgementActive, setAcknowledgementActive] = useState(false);
  const [acknowledged, setAcknowledged] = useState(false);
  const [menuActive, setMenuActive] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  // ...

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!cookies?.acknowledged) {
      setTimeout(() => {
        setAcknowledgementActive(true);
      }, 1000);
    }
  }, [cookies]);

  useEffect(() => {
    if (acknowledged) {
      setCookie([`acknowledged`], [], {
        path: `/`,
        sameSite: `lax`
      });
    }
  }, [acknowledged]);

  // ---------------------------------------------------------------------------
  // render

  const context = {
    acknowledged,
    setAcknowledged,
    acknowledgementActive,
    setAcknowledgementActive,
    menuActive,
    setMenuActive
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export default AppProvider;
