/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from "react";
import cn from "classnames";
import { useBackgroundColorStore } from "~store";
import {
  Acknowledgement,
  Footer,
  Header,
  Menu,
  NoJS,
  Theme,
  ViewportHeight
} from "~components";

import "~node_modules/modern-normalize/modern-normalize.css";
import "~styles/global.css";
import * as styles from "./styles.module.scss";

const Layout = ({ children, className }) => {
  const { backgroundColor } = useBackgroundColorStore((state) => state);

  useEffect(() => {
    if (typeof document === `undefined`) {
      return;
    }

    document.body.style.backgroundColor = backgroundColor;
  }, [backgroundColor]);

  return (
    <>
      <NoJS />

      <ViewportHeight />

      <Theme />

      <Header />
      <Menu />
      <Acknowledgement />

      <main className={cn(styles.container, className)}>{children}</main>

      <Footer />
    </>
  );
};

export default Layout;
