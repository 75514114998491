import { useStaticQuery, graphql } from "gatsby";

const useSanityEvents = () => {
  const { allSanityEvent } = useStaticQuery(
    graphql`
      query useSanityEvents {
        allSanityEvent {
          nodes {
            _id
            title
            slug {
              current
            }

            _rawBody
            body {
              _rawChildren
            }

            artist
            title
            sortableDate
            dates
            datesShort
            tickets
            ticketsShort
            venue

            tags {
              title
            }

            image {
              asset {
                gatsbyImageData
              }
            }

            imageXS {
              asset {
                gatsbyImageData
              }
            }

            cardImage {
              asset {
                gatsbyImageData
              }
            }

            accessibility
            warnings
            credits
            additionalInfo

            partners {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    `
  );

  const getAllTags = () => {
    const tags = new Set();

    allSanityEvent.nodes.forEach((event) => {
      event.tags.forEach((tag) => {
        tags.add(tag.title);
      });
    });

    return Array.from(tags);
  };

  return {
    allSanityEvent,
    getAllTags
  };
};

export default useSanityEvents;
