import React, { useState } from "react";
import cn from "classnames";
import { useWindowDimensions } from "~hooks";
import { BlockContent, Grid } from "~components";
import { validateEmail } from "~utils/helpers";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 *
 * @return {node}
 */
const ContactForm = ({
  data: {
    backgroundColor,
    fontColor,
    anchorId,
    title,
    borderTop,
    _rawBody: rawBody
  }
}) => {
  // ---------------------------------------------------------------------------
  // hooks

  const { isMobile } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [name, setName] = useState(``);
  const [email, setEmail] = useState(``);
  const [message, setMessage] = useState(``);
  const [formState, setFormState] = useState(`Idle`);
  const [formMessage, setFormMessage] = useState(``);

  if (anchorId) {
    anchorId = anchorId.replace(`#`, ``);
  }

  // ---------------------------------------------------------------------------
  // methods

  const contactFormSubmit = async (e) => {
    e.preventDefault();

    if (!process.env.GATSBY_FORMSPARK_FORM_ID) {
      // eslint-disable-next-line no-console
      console.error(`Required Formspark details are not set.`);
      return;
    }

    if (
      formState !== `Idle` ||
      name === `` ||
      email === `` ||
      !validateEmail(email) ||
      message === ``
    ) {
      setFormMessage(`Please fill out all fields.`);
      setFormState(`Error`);
    }

    setFormState(`Submitting`);

    const payload = { name, email, message };

    // todo: improve this; currently fire + forget
    setFormState(`Submitted`);
    setFormMessage(`Request received. Thank you.`);

    const response = await fetch(
      `https://submit-form.com/${process.env.GATSBY_FORMSPARK_FORM_ID}`,
      {
        method: `POST`,
        headers: {
          "Content-Type": `application/json`
        },
        body: JSON.stringify(payload)
      }
    );

    // todo: fix cors

    // try {
    //   const response = await fetch(
    //     `https://submit-form.com/${process.env.GATSBY_FORMSPARK_FORM_ID}`,
    //     {
    //       method: `POST`,
    //       headers: {
    //         "Content-Type": `application/json`
    //       },
    //       body: JSON.stringify(payload)
    //     }
    //   );

    //   console.log(`response: `, response);

    //   setFormState(`Submitted`);
    //   setFormMessage(`Request received. Thank you.`);
    // } catch (error) {
    //   setFormMessage(`Unexpected error.`);
    //   setFormState(`Error`);
    //   console.error(error);
    // }
  };

  // ---------------------------------------------------------------------------
  // render

  return (
    <section
      id={anchorId || ``}
      className={cn(styles.container, { [styles.borderTop]: borderTop })}
      style={{
        background: backgroundColor?.hex || `#33FF33`,
        color: fontColor?.hex || `black`
      }}
    >
      <Grid>
        <header className={styles.title}>
          <h2 className={isMobile ? `b2` : `caption`}>{title}</h2>
        </header>

        <div className={styles.body}>
          {rawBody && <BlockContent blocks={rawBody} />}

          <form
            id="contact-form"
            className={cn(styles.form, styles[`form${formState}`])}
            onSubmit={contactFormSubmit}
          >
            <input
              id="contact-form-name"
              name="name"
              className={cn(styles.formInput, `button-text`)}
              onChange={(e) => setName(e.target.value)}
              placeholder="NAME"
              required
              type="text"
            />

            <input
              id="contact-form-email"
              name="email"
              className={cn(styles.formInput, `button-text`)}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="EMAIL ADDRESS"
              required
              type="email"
            />

            <textarea
              id="contact-form-message"
              name="message"
              className={cn(styles.formTextarea, `button-text`)}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="MESSAGE"
              required
            />

            <input
              className={cn(styles.formSubmit, `button-text`)}
              disabled={formState === `Submitting` || formState === `Submitted`}
              type="submit"
            />

            <p className={cn(styles.formMessage, `b1`)}>{formMessage}</p>
          </form>
        </div>
      </Grid>
    </section>
  );
};

export default ContactForm;
