import React from "react";
import { ExpanderCard, Grid } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Global header component
 *
 * @return {node}
 */
const ExpanderGrid = ({
  data: { backgroundColor, anchorId, fontColor, heading, items }
}) => {
  if (anchorId) {
    anchorId = anchorId.replace(`#`, ``);
  }

  return (
    <section
      id={anchorId || ``}
      className={styles.container}
      style={{
        background: backgroundColor?.hex || `#33FF33`,
        color: fontColor?.hex || `black`
      }}
    >
      <Grid>
        {heading && (
          <div className={styles.gridHeading}>
            <h2 className="b2">{heading}</h2>
          </div>
        )}

        {items.map((item) => (
          <div key={item._id} className={styles.cardContainer}>
            <ExpanderCard data={item} />
          </div>
        ))}
      </Grid>
    </section>
  );
};

export default ExpanderGrid;
