const glsl = require(`glslify`);

const shader = glsl(`
  precision highp float;

  uniform sampler2D tex;
  uniform vec2 cursorPosition;

  varying vec3 vPosition;
  varying vec2 vUv;
  varying vec3 vColor;
  varying float vBlink;

  void main() {
    vec2 p = vUv * 2.0 - 1.0;

    float dist = length(p - cursorPosition);
    float fog = smoothstep(0.8, 1.0, dist);

    vec4 texColor = texture2D(tex, vUv);
    vec3 color = (texColor.rgb - vBlink * length(p) * 1.0) * vColor;
    float opacity = texColor.a * (0.2 + 0.8 * fog);

    gl_FragColor = vec4(color, opacity);
  }
`);

export default shader;
