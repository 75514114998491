// extracted by mini-css-extract-plugin
export var brand = "styles-module--brand--17e1a";
export var brandCard = "styles-module--brandCard--02f34";
export var container = "styles-module--container--bbf01";
export var containerRelative = "styles-module--containerRelative--07b4f";
export var content = "styles-module--content--21700";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--d4ffe";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var micro = "styles-module--micro--1bb34";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";