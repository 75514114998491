import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useApp, useKeyPress, usePathname, useSanitySettings } from "~hooks";
import { Link, SmokeMachine } from "~components";
import { ReactComponent as X } from "~assets/svg/x-circle-thin.svg";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Global header component
 *
 * @return {node}
 */
const Menu = () => {
  // ---------------------------------------------------------------------------
  // hooks

  const { menuActive, setMenuActive } = useApp();
  const escKeyPressed = useKeyPress(`Escape`);
  const pathname = usePathname();
  const { sanitySettings } = useSanitySettings();
  const { live, menuLinks } = sanitySettings;

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [smokeActive, setSmokeActive] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (escKeyPressed) {
      setMenuActive(false);
    }
  }, [escKeyPressed]);

  useEffect(() => {
    if (menuActive) {
      setTimeout(() => {
        setSmokeActive(true);
      }, 500);
    } else {
      setSmokeActive(false);
    }
  }, [menuActive]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div
      className={cn(styles.container, {
        [styles.containerActive]: menuActive ? styles.containerActive : null
      })}
    >
      <div className={styles.background}>
        <SmokeMachine shouldRender={smokeActive} />
      </div>

      <div className={styles.closeButton}>
        <button type="button" onClick={() => setMenuActive(false)}>
          <X />
        </button>
      </div>

      <ul className={styles.list}>
        <li className={styles.link}>
          <Link to="/" onClick={() => setMenuActive(false)}>
            <span className="h1">YIRRAMBOI</span>
            <div className={styles.linkDot} />
          </Link>
        </li>

        {menuLinks?.[0] &&
          menuLinks.map(({ text, url }, index) => (
            <li
              key={`$menu-link-text-${index}`}
              className={cn(styles.link, {
                [styles.linkActive]:
                  pathname === `${url}/` ? styles.linkActive : null
              })}
            >
              <Link to={url} onClick={() => setMenuActive(false)}>
                <span className="h1">{text}</span>
                <div className={styles.linkDot} />
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Menu;
