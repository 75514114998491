import React from "react";
import { Colors, Fonts, ViewportHeight } from "~components";

const Theme = () => (
  <>
    <Colors />

    <Fonts />

    <ViewportHeight />
  </>
);

export default Theme;
