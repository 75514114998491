import React from "react";
import cn from "classnames";
import { Link } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Animated marquee links.
 *
 * @param {Object} props.data
 * @param {Object} props.data.backgroundColor
 * @param {Object} props.data.fontColor
 * @param {Array} props.data.rows
 *
 * @return {JSX.Element} Marquee slice.
 */
const Marquee = ({ data: { backgroundColor, fontColor, rows } }) => {
  if (!rows?.[0]) {
    return null;
  }

  return (
    <section
      className={styles.container}
      style={{
        background: backgroundColor?.hex || `transparent`,
        color: fontColor?.hex || `black`
      }}
    >
      <article className={styles.marquee}>
        {rows.map((link, rowIndex) => (
          <Link
            key={`marquee-row-${rowIndex}`}
            to={link.url}
            className={styles.marqueeRow}
          >
            {Array(30)
              .fill(null)
              .map((_, textIndex) => (
                <div
                  key={`marquee-text-${textIndex}`}
                  className={cn(
                    styles.marqueeRowText,
                    rowIndex % 2 === 0
                      ? styles.marqueeRowTextLeft
                      : styles.marqueeRowTextRight
                  )}
                >
                  <h2 className="h1">{link.text}&nbsp;·&nbsp;</h2>
                </div>
              ))}
          </Link>
        ))}
      </article>
    </section>
  );
};

export default Marquee;
