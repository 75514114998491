import React, { useState } from "react";
import cn from "classnames";
import { useSanitySettings, useWindowDimensions } from "~hooks";
import { Grid, Image, Link } from "~components";
import { validateEmail } from "~utils/helpers";

import { ReactComponent as Facebook } from "~assets/svg/facebook.svg";
import { ReactComponent as Instagram } from "~assets/svg/instagram.svg";
import { ReactComponent as Mail } from "~assets/svg/mail.svg";
import { ReactComponent as YouTube } from "~assets/svg/youtube.svg";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Global footer component
 *
 * @return {node}
 */
const Footer = () => {
  // ---------------------------------------------------------------------------
  // hooks / store

  const { sanitySettings } = useSanitySettings();
  const { isDesktop } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [name, setName] = useState(``);
  const [email, setEmail] = useState(``);
  const [formState, setFormState] = useState(`Idle`);
  const [formMessage, setFormMessage] = useState(``);

  const { affiliates, links, copyright, credits, formHeading, socials } =
    sanitySettings;

  // ---------------------------------------------------------------------------
  // methods

  const newsletterFormSubmit = async (e) => {
    e.preventDefault();

    if (
      !process.env.GATSBY_CAMPAIGN_MONITOR_LIST_ID ||
      !process.env.GATSBY_CAMPAIGN_MONITOR_API_KEY
    ) {
      console.error(`Required Campaign Monitor details are not set.`);
      return;
    }

    if (
      formState !== `Idle` ||
      name === `` ||
      email === `` ||
      !validateEmail(email)
    ) {
      setFormMessage(`Please fill out all fields.`);
      setFormState(`Error`);
      return;
    }

    setFormState(`Submitting`);

    const data = {
      email,
      name
    };

    const response = await fetch(`/api/campaignMonitor`, {
      method: `POST`,
      headers: {
        "Content-Type": `application/json`
      },
      body: JSON.stringify(data)
    });
    if (response.ok) {
      setFormState(`Submitted`);
      setFormMessage(`Request received. Thank you.`);
    } else {
      setFormState(`Error`);
      setFormMessage(`Unexpected error.`);
    }
  };

  // ---------------------------------------------------------------------------
  // render

  const affiliatesJSX = (
    <div className={styles.affiliates}>
      {affiliates?.[0] &&
        affiliates.map((affiliate, affiliateIndex) => (
          <div key={`affiliate-${affiliateIndex}`} className={styles.affiliate}>
            <Image image={affiliate} />
          </div>
        ))}
    </div>
  );

  const linksJSX = (
    <ul className={cn(styles.links)}>
      {links?.[0] &&
        links.map((link) => (
          <li
            key={`footer-link-${link.text}`}
            className={cn(`caption`, styles.link)}
          >
            <Link to={link.url}>{link.text}</Link>
          </li>
        ))}
    </ul>
  );

  const copyrightJSX = (
    <div className={cn(`caption`, styles.copyright)}>{copyright}</div>
  );

  const creditsJSX = (
    <div className={cn(`caption`, styles.credit)}>{credits}</div>
  );

  const socialsJSX = (
    <ul className={styles.socials}>
      {socials?.[0] &&
        socials.map(({ text, url }) => {
          let icon;
          let className = ``;

          switch (text?.toLowerCase()) {
            case `facebook`:
              icon = <Facebook />;
              className = cn(styles.socialsIcon, styles.socialsIconFB);
              break;

            case `instagram`:
              icon = <Instagram />;
              className = cn(styles.socialsIcon, styles.socialsIconIG);
              break;

            case `youtube`:
              icon = <YouTube />;
              className = cn(styles.socialsIcon, styles.socialsIconYT);
              break;

            case `mail`:
            case `email`:
              icon = <Mail />;
              className = cn(styles.socialsIcon, styles.socialsIconM);
              break;

            default:
              break;
          }

          if (!icon) {
            return null;
          }

          return (
            <li className={className}>
              <Link to={url}>{icon}</Link>
            </li>
          );
        })}
    </ul>
  );

  return (
    <footer className={styles.container}>
      <Grid>
        <form
          id="newsletter-form"
          className={cn(styles.form, styles[`form${formState}`])}
          onSubmit={newsletterFormSubmit}
        >
          <h4 className={cn(`b2`, styles.formHeading)}>{formHeading}</h4>

          <div className={styles.formInput}>
            <input
              id="footer-newsletter-name"
              name="name"
              className={cn(`b2`)}
              onChange={(e) => setName(e.target.value)}
              placeholder="NAME"
              readOnly={formState !== `Idle`}
              required
              type="text"
              value={name}
            />
          </div>

          <div className={styles.formInput}>
            <input
              id="footer-newsletter-email"
              name="email"
              className={cn(`b2`)}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="EMAIL ADDRESS"
              readOnly={formState !== `Idle`}
              required
              type="email"
              value={email}
            />
          </div>

          <input
            className={cn(styles.formSubmit, `button-text`)}
            disabled={formState === `Submitting` || formState === `Submitted`}
            type="submit"
          />

          <p className={cn(styles.formMessage, `b1`)}>{formMessage}</p>
        </form>

        {isDesktop && affiliatesJSX}
      </Grid>

      {(!isDesktop && (
        <Grid className={styles.mobileMeta}>
          <div className={styles.mobileMetaHalf}>
            {socialsJSX}
            {linksJSX}
          </div>

          <div className={styles.mobileMetaHalf}>{affiliatesJSX}</div>

          <div className={styles.mobileMetaFull}>
            {copyrightJSX}
            {creditsJSX}
          </div>
        </Grid>
      )) || (
        <Grid className={styles.meta}>
          {linksJSX}
          {creditsJSX}
          {socialsJSX}
          {copyrightJSX}
        </Grid>
      )}
    </footer>
  );
};

export default Footer;
