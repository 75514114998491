// extracted by mini-css-extract-plugin
export var appearFromBelow = "styles-module--appear-from-below--935c8";
export var appearFromLeft = "styles-module--appear-from-left--64968";
export var appearFromRight = "styles-module--appear-from-right--4c623";
export var bigline = "styles-module--bigline--a056c";
export var container = "styles-module--container--cf9be";
export var desktop = "1340px";
export var giant = "2200px";
export var image = "styles-module--image--3ce2f";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var marquee = "styles-module--marquee--5b6f2";
export var marqueeContainer = "styles-module--marqueeContainer--dd12d";
export var marqueeContainerGlitch = "styles-module--marqueeContainerGlitch--feb60";
export var marqueeContainerGlitchActive = "styles-module--marqueeContainerGlitchActive--d8209";
export var marqueeContainerGlitchSkewed = "styles-module--marqueeContainerGlitchSkewed--54682";
export var marqueeRow = "styles-module--marqueeRow--5c15d";
export var marqueeRowText = "styles-module--marqueeRowText--5cc4e";
export var marqueeRowTextPadded = "styles-module--marqueeRowTextPadded--ea577";
export var media = "styles-module--media--eb26b";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var video = "styles-module--video--91b58";
export var vignetteContainer = "styles-module--vignette-container--e2005";