// extracted by mini-css-extract-plugin
export var container = "styles-module--container--10324";
export var containerRelative = "styles-module--containerRelative--39234";
export var containerVisible = "styles-module--containerVisible--b1363";
export var content = "styles-module--content--26f56";
export var desktop = "1340px";
export var giant = "2200px";
export var grid = "styles-module--grid--2aec4";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var micro = "styles-module--micro--61908";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";