import React, { useRef } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import {
  InstancedBufferAttribute,
  InstancedBufferGeometry,
  RawShaderMaterial,
  AdditiveBlending,
  PlaneGeometry,
  TextureLoader,
  DoubleSide
} from "three";
import * as THREE from "three";
import fogVertex from "./glsl/fog.vertex";
import fogFragment from "./glsl/fog.fragment";

const Fog = ({ background, dimensions, num = 50 }) => {
  const tex = useLoader(TextureLoader, `/webgl/fog/fog-inverted.png`);

  let backgroundTexture;

  if (background && dimensions?.width && dimensions?.height) {
    backgroundTexture = useLoader(TextureLoader, background);
  }

  const uniforms = React.useMemo(
    () => ({
      time: { type: `f`, value: 0 },
      tex: { type: `t`, value: tex },
      mouse: { type: `v2`, value: new THREE.Vector2() }
    }),
    [tex]
  );

  const geometry = React.useMemo(() => {
    const baseGeometry = new PlaneGeometry(1100, 1100, 20, 20);
    const instancedGeometry = new InstancedBufferGeometry().copy(baseGeometry);
    const instancePositions = new InstancedBufferAttribute(
      new Float32Array(num * 3),
      3
    );
    const delays = new InstancedBufferAttribute(new Float32Array(num), 1);
    const rotates = new InstancedBufferAttribute(new Float32Array(num), 1);

    // eslint-disable-next-line no-plusplus
    for (let i = 0, ul = num; i < ul; i++) {
      instancePositions.setXYZ(
        i,
        (Math.random() * 2 - 1) * 850,
        0,
        (Math.random() * 2 - 1) * 300
      );
      delays.setXYZ(i, Math.random());
      rotates.setXYZ(i, Math.random() * 2 + 1);
    }

    instancedGeometry.setAttribute(`instancePosition`, instancePositions);
    instancedGeometry.setAttribute(`delay`, delays);
    instancedGeometry.setAttribute(`rotate`, rotates);

    return instancedGeometry;
  }, []);

  const material = React.useMemo(
    () =>
      new RawShaderMaterial({
        uniforms,
        vertexShader: fogVertex,
        fragmentShader: fogFragment,
        transparent: true,
        depthWrite: false,
        blending: AdditiveBlending
      }),
    [uniforms]
  );

  const meshRef = useRef();

  useFrame((state, delta) => {
    uniforms.time.value += delta;
  });

  return (
    <>
      <instancedMesh ref={meshRef} args={[geometry, material, num]} />

      {dimensions && (
        <mesh position={[0, 0, -10]}>
          <planeGeometry args={[dimensions.width, dimensions.height]} />
          <meshBasicMaterial map={backgroundTexture} side={DoubleSide} />
        </mesh>
      )}
    </>
  );
};

export default Fog;
