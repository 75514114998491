/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { useExpandable } from "~hooks";
import { BlockContent, Grid } from "~components";

import { ReactComponent as ExpandIcon } from "~assets/svg/expand.svg";
import { ReactComponent as CollapseIcon } from "~assets/svg/collapse.svg";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * ExpandableTextArticle component.
 *
 * @return {node}
 */
const ExpandableTextArticle = ({
  data: {
    backgroundColor,
    fontColor,
    title,
    borderTop,
    anchorId,
    _rawBody: rawBody,
    _rawExpandableBody: rawExpandableBody
  }
}) => {
  if (anchorId) {
    anchorId = anchorId.replace(`#`, ``);
  }

  // ---------------------------------------------------------------------------
  // context / ref / state

  const { contentHeight, expanded, setExpanded, expandableRef } =
    useExpandable();

  const bodyRef = useRef();

  const [bodyHeight, setBodyHeight] = useState(0);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const handleResize = () => {
      if (!bodyRef.current) {
        return;
      }

      setBodyHeight(bodyRef.current.clientHeight);
    };

    // Initial call
    handleResize();

    // Event listener for window resize
    window.addEventListener(`resize`, handleResize);

    // Cleanup
    return () => {
      window.removeEventListener(`resize`, handleResize);
    };
  }, [bodyRef]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <section
      id={anchorId}
      className={cn(styles.container, { [styles.borderTop]: borderTop })}
      style={{
        background: backgroundColor?.hex || `#33FF33`,
        color: fontColor?.hex || `black`
      }}
    >
      <Grid>
        {rawExpandableBody && (
          <div className={styles.buttonContainer}>
            <div className={styles.button}>
              <button type="button" onClick={() => setExpanded(!expanded)}>
                <div className={styles.buttonIcon}>
                  {expanded ? <CollapseIcon /> : <ExpandIcon />}
                </div>
              </button>
            </div>
          </div>
        )}

        <header className={styles.title}>
          <h2 className="caption">{title}</h2>
        </header>

        <div className={styles.body}>
          <div ref={bodyRef}>
            {rawBody && <BlockContent blocks={rawBody} />}
          </div>

          <div
            ref={expandableRef}
            className={cn(styles.expandableBodyHidden, {
              [styles.expandableBodyFixed]:
                contentHeight !== null ? styles.expandableBodyFixed : null
            })}
            aria-hidden
            aria-label="Expandable content sizer"
          >
            {rawExpandableBody && <BlockContent blocks={rawExpandableBody} />}
          </div>

          <div
            className={styles.expandableBody}
            style={{
              maxHeight: expanded ? contentHeight + bodyHeight : 0
            }}
            aria-label="Expandable content"
          >
            {rawExpandableBody && <BlockContent blocks={rawExpandableBody} />}
          </div>
        </div>
      </Grid>
    </section>
  );
};

export default ExpandableTextArticle;
