import { globalHistory } from "@reach/router";
import { useEffect } from "react";
import { usePathnameStore } from "~store";

export const usePathname = () => {
  const { pathname, setPathname } = usePathnameStore((state) => state);

  useEffect(() => {
    const unlisten = globalHistory.listen(({ location }) => {
      setPathname(location.pathname);
    });

    return () => {
      unlisten();
    };
  }, [setPathname]);

  return pathname;
};

export default usePathname;
