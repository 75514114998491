// extracted by mini-css-extract-plugin
export var container = "styles-module--container--e72df";
export var desktop = "1340px";
export var expandButton = "styles-module--expandButton--9fb00";
export var expandButtonContainer = "styles-module--expandButtonContainer--a706c";
export var expandButtonIcon = "styles-module--expandButtonIcon--b8977";
export var expandableBody = "styles-module--expandableBody--89b71";
export var expandableBodyFixed = "styles-module--expandableBodyFixed--0d356";
export var expandableBodyHidden = "styles-module--expandableBodyHidden--2848b";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subtitle = "styles-module--subtitle--20751";
export var tablet = "768px";
export var tag = "styles-module--tag--2ee9f";
export var textContent = "styles-module--textContent--b15f3";
export var venue = "styles-module--venue--02b5b";