import { create } from "zustand";
import { globalHistory } from "@reach/router";

const usePathnameStore = create((set) => ({
  pathname: globalHistory.location.pathname,
  setPathname: (pathname) => {
    set({ pathname });
  }
}));

export default usePathnameStore;
