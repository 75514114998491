const links = [
  {
    category: `Elements`,
    label: `01. What are we working with?`,
    navLabel: `01.0 Elements`,
    url: `/what-are-we-working-with`
  },
  {
    category: `Environment`,
    label: `02. The Water We Swim In`,
    navLabel: `02.0 Environment`,
    url: `/the-water-we-swim-in`
  },
  {
    category: `Essence`,
    label: `03. Our Purpose`,
    navLabel: `03.0 Essence`,
    url: `/our-purpose`
  },
  {
    category: `Essence`,
    label: `04. Design Principles`,
    navLabel: `04.0 Design Principles`,
    url: `/design-principles`
  },
  {
    category: `Explanation`,
    label: `05. Logo`,
    navLabel: `05.0 Logo`,
    url: `/logo`
  },
  {
    category: `Explanation`,
    label: `06. Colors`,
    navLabel: `06.0 Color`,
    url: `/colors`
  },
  {
    category: `Explanation`,
    label: `07. Typography`,
    navLabel: `07.0 Typography`,
    url: `/typography`
  },
  {
    category: `Explanation`,
    label: `08. Tone of Voice`,
    navLabel: `08.0 Tone of Voice`,
    url: `/tone-of-voice`
  },
  {
    category: `Explanation`,
    label: `09. Graphic Language`,
    navLabel: `09.0 Graphic Language`,
    url: `/graphic-language`
  },
  {
    category: `Explanation`,
    label: `10. Art Direction`,
    navLabel: `10.0 Art Direction`,
    url: `/art-direction`
  },
  {
    category: `Explanation`,
    label: `11. Motion Principles`,
    navLabel: `11.0 Motion Principles`,
    url: `/motion-principles`
  },
  {
    category: `Explanation`,
    label: `12. CSS Themes`,
    navLabel: `12.0 CSS Themes`,
    url: `/css-themes`
  },
  {
    category: `Explanation`,
    label: `13. Web Components`,
    navLabel: `13.0 Web Components`,
    url: `/web-components`
  },
  {
    category: `Explanation`,
    label: `14. Page Builder Slices`,
    navLabel: `14.0 Page Builder Slices`,
    url: `/page-builder-slices`
  },
  {
    category: `Expression`,
    label: `15. Brand Exploration`,
    navLabel: `15.0 Brand Exploration`,
    url: `/brand-exploration`
  },
  {
    category: `Expression`,
    label: `16. Links + Resources`,
    navLabel: `16.0 Links + Resources`,
    url: `/links-and-resources`
  }
];

export default links;
