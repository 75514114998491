import { useStaticQuery, graphql } from "gatsby";

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      fragment SiteFragment on Site {
        siteMetadata {
          description
          siteName
          title
          siteUrl
        }
      }

      query SiteMetaData {
        site {
          ...SiteFragment
        }
      }
    `
  );

  return site.siteMetadata;
};

export default useSiteMetadata;
