/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { useSlices } from "~hooks";
import * as Slices from "~slices";

const SanitySlice = (props) => {
  const { data } = props;

  // uncomment this line to log a list of fragments for use in page queries
  // const { fragments } = useDBPFragments();
  // console.log(`fragments: `, fragments);

  const { Slices: DBPSlices } = useSlices();
  const { _type: sliceType } = data;

  if (!sliceType) {
    // eslint-disable-next-line no-console
    console.error(
      `Please pass a _type from your template to the Section component`
    );

    return null;
  }

  const sectionName =
    sliceType[0].toUpperCase() + sliceType.slice(1, sliceType.length);

  const SliceSection = Slices?.[sectionName] || DBPSlices?.[sectionName];

  if (!SliceSection) {
    // eslint-disable-next-line no-console
    console.error(
      `Can't find Section ${sectionName}, are you sure it exists in the sections directory?`
    );

    return null;
  }

  return <SliceSection id={`Sanity${sectionName}`} {...props} />;
};

export default SanitySlice;
