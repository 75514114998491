// extracted by mini-css-extract-plugin
export var container = "styles-module--container--c0290";
export var containerActive = "styles-module--containerActive--7ef7d";
export var desktop = "1340px";
export var giant = "2200px";
export var glitch = "styles-module--glitch--17947";
export var glitch1 = "styles-module--glitch1--a83ad";
export var glitch2 = "styles-module--glitch2--730d6";
export var glitch3 = "styles-module--glitch3--d5789";
export var glitchOverlay = "styles-module--glitchOverlay--c9441";
export var glitcher1 = "styles-module--glitcher-1--d92bd";
export var glitcher2 = "styles-module--glitcher-2--912a5";
export var glitcher3 = "styles-module--glitcher-3--bee30";
export var image = "styles-module--image--029e2";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var tag = "styles-module--tag--d30fd";