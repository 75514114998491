import React from "react";

const SEOTwitter = ({ username, title, desc, image }) => (
  <>
    {username && <meta name="twitter:creator" content={username} />}

    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={desc} />
    <meta name="twitter:image" content={image} />
    <meta name="twitter:image:alt" content={desc} />
  </>
);

export default SEOTwitter;
