// extracted by mini-css-extract-plugin
export var button = "styles-module--button--147b9";
export var container = "styles-module--container--d4d16";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var subtitle = "styles-module--subtitle--71a9e";
export var tablet = "768px";
export var tag = "styles-module--tag--84b28";
export var tags = "styles-module--tags--26231";
export var venue = "styles-module--venue--caddb";