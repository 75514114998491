import React from "react";
import cn from "classnames";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Prodivdes global grid layout
 */
const Grid = ({ children, className }) => (
  <div className={cn(styles.grid, className)}>{children}</div>
);

export default Grid;
