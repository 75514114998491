// extracted by mini-css-extract-plugin
export var background = "styles-module--background--ec40e";
export var closeButton = "styles-module--closeButton--fed9f";
export var container = "styles-module--container--25c40";
export var containerActive = "styles-module--containerActive--31bcb";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var link = "styles-module--link--b9e11";
export var linkActive = "styles-module--linkActive--6c1fd";
export var linkDot = "styles-module--linkDot--a24f8";
export var list = "styles-module--list--53582";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";