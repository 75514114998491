export const useSlices = (slices) => {
  const getSliceByName = (type) =>
    slices.find((slice) => type?.toLowerCase() === slice?._type?.toLowerCase());

  const getSlicesByName = (types) =>
    slices.filter((slice) =>
      types
        .map((type) => type.toLowerCase())
        .includes(slice?._type?.toLowerCase())
    );

  // ---------------------------------------------------------------------------

  return {
    getSliceByName,
    getSlicesByName,
    slices
  };
};

export default useSlices;
