import { useEffect, useRef, useState } from "react";

export const useExpandable = () => {
  const expandableRef = useRef();

  const [expanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(null);

  useEffect(() => {
    if (!expandableRef?.current) {
      return;
    }

    const boundingClientRect = expandableRef.current.getBoundingClientRect();

    setContentHeight(boundingClientRect.height);
  }, [expandableRef]);

  return {
    expandableRef,
    expanded,
    setExpanded,
    contentHeight
  };
};

export default useExpandable;
