// extracted by mini-css-extract-plugin
export var body = "styles-module--body--7ac43";
export var borderTop = "styles-module--borderTop--bce7e";
export var container = "styles-module--container--a0407";
export var desktop = "1340px";
export var form = "styles-module--form--01f64";
export var formError = "styles-module--formError--1275d";
export var formInput = "styles-module--formInput--8a2bb";
export var formMessage = "styles-module--formMessage--2d397";
export var formSubmit = "styles-module--formSubmit--ad684";
export var formSubmitted = "styles-module--formSubmitted--7a06f";
export var formSubmitting = "styles-module--formSubmitting--7f7f6";
export var formTextarea = "styles-module--formTextarea--c8bee";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--c8659";