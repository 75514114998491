/* eslint-disable react/jsx-props-no-spreading */

import React, { useRef } from "react";
import cn from "classnames";
import { Grid } from "~components";
import * as styles from "./styles.module.scss";

const ATF = ({ relative = false }) => {
  // console.log(`ATF inView: `, inView);

  // ---------------------------------------------------------------------------
  // context / ref / state

  let i;
  // console.log(`ref: `, ref);
  // console.log(`offset: `, offset);
  // console.log(`top: `, top);

  // ---------------------------------------------------------------------------
  // render

  return (
    <section
      className={cn(styles.container, {
        [styles.containerRelative]: relative ? styles.containerRelative : null
      })}
    >
      <Grid className={cn(styles.grid)}>
        {/* <article className={cn(styles.content)}></article> */}
      </Grid>
    </section>
  );
};

export default ATF;
