/* eslint-disable react/button-has-type */

import React, { useState } from "react";
import cn from "classnames";
import { Button, GlitchImage, Link } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Card component, used for Events.
 *
 * @param {string} props.buttonTarget
 * @param {string} props.buttonText
 * @param {Object} props.data
 * @param {Object} props.data.cardImage
 * @param {string} props.data.title
 * @param {string} props.data.subtitle
 * @param {Array} props.data.tags
 *
 * @return {JSX.Element} Card component.
 */
const Card = ({
  buttonTarget,
  buttonText,
  data: { cardImage, title, subtitle, venue, tags }
}) => {
  const [hovering, setHovering] = useState(false);

  return (
    <article
      className={cn(styles.container)}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <Link to={buttonTarget}>
        <GlitchImage active={hovering} image={cardImage} />

        {tags?.[0] && (
          <div className={styles.tags}>
            {tags.map((tag) => (
              <Button
                key={`button-tag-${tag.title}`}
                className={styles.tag}
                color="green"
                transparent
              >
                <span className="button-text">{tag.title}</span>
              </Button>
            ))}
          </div>
        )}
      </Link>

      <h3 className="h3">{title}</h3>

      <h4 className={cn(`caption`, styles.subtitle)}>{subtitle}</h4>

      {venue && venue?.trim() !== `` && (
        <h4 className={cn(`caption`, styles.venue)}>{venue.split(`\n`)[0]}</h4>
      )}

      <Link to={buttonTarget}>
        <Button className={cn(styles.button)} color="black" transparent>
          <span className="button-text">{buttonText}</span>
        </Button>
      </Link>
    </article>
  );
};

export default Card;
