/* eslint-disable react/jsx-props-no-spreading */

import React from "react";

import { ReactComponent as ArrowDown } from "~assets/svg/icon-library/arrow-down.svg";
import { ReactComponent as ArrowLeft } from "~assets/svg/icon-library/arrow-left.svg";
import { ReactComponent as ArrowRight } from "~assets/svg/icon-library/arrow-right.svg";
import { ReactComponent as Cart } from "~assets/svg/icon-library/cart.svg";
import { ReactComponent as LookingGlass } from "~assets/svg/icon-library/looking-glass.svg";
import { ReactComponent as Minus } from "~assets/svg/icon-library/minus.svg";
import { ReactComponent as PlayCircle } from "~assets/svg/icon-library/play-circle.svg";
import { ReactComponent as Plus } from "~assets/svg/icon-library/plus.svg";
import { ReactComponent as StarFilled } from "~assets/svg/icon-library/star-filled.svg";
import { ReactComponent as Star } from "~assets/svg/icon-library/star.svg";
import { ReactComponent as SunFilled } from "~assets/svg/icon-library/sun-filled.svg";
import { ReactComponent as WarningTriangle } from "~assets/svg/icon-library/warning-triangle.svg";
import { ReactComponent as X } from "~assets/svg/icon-library/x.svg";

const svgs = {
  ArrowDown,
  ArrowLeft,
  ArrowRight,
  Cart,
  LookingGlass,
  Minus,
  PlayCircle,
  Plus,
  StarFilled,
  Star,
  SunFilled,
  WarningTriangle,
  X
};

/** ============================================================================
 * @component
 * Used to render SVG icons
 * - How do we add extra SVGs from the local instance?
 * - Something to do with relative/abs imports?
 *    - Try the static directory
 *    - Try import * from ~assets/svg/*.svg
 *
 * @param {string}     svg     Name of desired SVG, must be imported first
 *
 * @return {node}
 */
const SVG = ({ svg, ...props }) => {
  if (!svgs?.[svg]) {
    return null;
  }

  const SVGElement = svgs[svg];

  return <SVGElement {...props} aria-hidden />;
};

export default SVG;
