/* eslint-disable react/jsx-props-no-spreading */

import React, { useRef } from "react";
import cn from "classnames";
import { Button, Grid, ReleaseScheduleCard } from "~components";
import * as styles from "./styles.module.scss";

const ReleaseSchedule = ({ relative = false }) => {
  // console.log(`ReleaseSchedule inView: `, inView);

  // ---------------------------------------------------------------------------
  // context / ref / state

  let i;
  // console.log(`ref: `, ref);
  // console.log(`offset: `, offset);
  // console.log(`top: `, top);

  const cards = [
    {
      heading: `Genome 1.0`,
      body: `Generate social media posts with caption and images in line with your brand tone of voice and look & feel.`,
      tags: [
        {
          label: `GENERATE`,
          color: `grey`,
          live: false
        },
        {
          label: `LIVE NOW`,
          color: `lilac`,
          live: true
        }
      ]
    },
    {
      heading: `Genome 1.1`,
      body: `Support for video generation and brand moodboard.`,
      tags: [
        {
          label: `GENERATE`,
          color: `grey`,
          live: false
        },
        {
          label: `APR 2023`,
          color: `lilac`,
          live: false
        }
      ]
    },
    {
      heading: `Genome 1.2`,
      body: `Generate your own branded website in a few clicks.`,
      tags: [
        {
          label: `GENERATE`,
          color: `grey`,
          live: false
        },
        {
          label: `MAY 2023`,
          color: `lilac`,
          live: false
        }
      ]
    },
    {
      heading: `Genome 2.0`,
      body: `Support for social media posting optimisation and management. SEO for website.`,
      tags: [
        {
          label: `GENERATE`,
          color: `grey`,
          live: false
        },
        {
          label: `JUL 2023`,
          color: `lilac`,
          live: false
        }
      ]
    },
    {
      heading: `Genome 2.1`,
      body: `Support for community engagement with email marketing and newsletters.`,
      tags: [
        {
          label: `GENERATE`,
          color: `grey`,
          live: false
        },
        {
          label: `AUG 2023`,
          color: `lilac`,
          live: false
        }
      ]
    },
    {
      heading: `Genome 3.0`,
      body: `Create, onboard and/or automate your e-commerce business.`,
      tags: [
        {
          label: `GENERATE`,
          color: `grey`,
          live: false
        },
        {
          label: `NOW 2023`,
          color: `lilac`,
          live: false
        }
      ]
    }
  ];

  // ---------------------------------------------------------------------------
  // render

  return (
    <section
      className={cn(styles.container, {
        [styles.containerRelative]: relative ? styles.containerRelative : null
      })}
    >
      <Grid className={cn(styles.grid)} node="article">
        <nav className={cn(styles.sidebar)}>
          <h2 className="h2">
            Genome Release
            <br />
            Schedule
          </h2>

          {/* todo: component? */}
          <form className={styles.newsletter}>
            <p className={cn(styles.newsletterBody, `b2`)}>
              We’ve got many exciting features coming. Join the waitlist to keep
              up with the latest updates.
            </p>

            <input
              className={cn(styles.newsletterInput, `button-text`)}
              placeholder="ENTER EMAIL"
              required
              type="text"
            />

            <Button color="lime" type="submit">
              <span className="button-text">JOIN WAITLIST</span>
            </Button>
          </form>
        </nav>

        <ul className={cn(styles.cards)}>
          {cards.map((card) => (
            <li key={`card-${card.heading}`} className={cn(styles.card)}>
              <ReleaseScheduleCard data={card} />
            </li>
          ))}
        </ul>
      </Grid>

      <Grid className={cn(styles.cards)}>
        <article className={cn(styles.card)}>
          <></>
        </article>

        <article className={cn(styles.card)}>
          <></>
        </article>

        <article className={cn(styles.card)}>
          <></>
        </article>
      </Grid>
    </section>
  );
};

export default ReleaseSchedule;
