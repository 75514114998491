// extracted by mini-css-extract-plugin
export var affiliate = "styles-module--affiliate--04ca6";
export var affiliates = "styles-module--affiliates--c0082";
export var container = "styles-module--container--1b805";
export var copyright = "styles-module--copyright--77199";
export var credit = "styles-module--credit--4c99c";
export var desktop = "1340px";
export var form = "styles-module--form--b0f9b";
export var formError = "styles-module--formError--56779";
export var formHeading = "styles-module--formHeading--c3694";
export var formInput = "styles-module--formInput--6f137";
export var formMessage = "styles-module--formMessage--0976f";
export var formSubmit = "styles-module--formSubmit--be3de";
export var formSubmitted = "styles-module--formSubmitted--febd0";
export var formSubmitting = "styles-module--formSubmitting--67edf";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1024px";
export var link = "styles-module--link--512c0";
export var links = "styles-module--links--6d6f6";
export var meta = "styles-module--meta--e6bc3";
export var mobile = "400px";
export var mobileMeta = "styles-module--mobileMeta--41174";
export var mobileMetaFull = "styles-module--mobileMetaFull--28c36";
export var mobileMetaHalf = "styles-module--mobileMetaHalf--1ba65";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var socials = "styles-module--socials--c7b46";
export var socialsIcon = "styles-module--socialsIcon--8ff2e";
export var socialsIconFB = "styles-module--socialsIconFB--b35b7";
export var socialsIconIG = "styles-module--socialsIconIG--f37a9";
export var socialsIconM = "styles-module--socialsIconM--f329e";
export var socialsIconYT = "styles-module--socialsIconYT--7b853";
export var tablet = "768px";