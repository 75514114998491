/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import cn from "classnames";
import { capitalizeString } from "~utils/helpers";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @css
 */
export const BUTTON_HEIGHT = `51px`;

export const BUTTON_STYLES = {};

/** ============================================================================
 * @component
 * Core Button component.
 * @param  {string}    buttonType   Usually one of "button" or "submit"
 * @param  {node}      children     Button text, icons if required
 * @param  {string}    className    Can be an identifier, Emotion CSS, or both
 * @param  {string}    color        Key to grab CSS from BUTTON_STYLES above
 * @param  {function}  onClick      Click listener function
 * @param  {boolean}   transparent  Fill state of the button
 * @return {node}                   Compiled Button JSX
 */
const Button = ({
  buttonType = `button`,
  children,
  className = ``,
  color = `black`,
  transparent = false,
  onClick
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <button
      type={buttonType}
      className={cn(
        styles.container,
        styles?.[color] || null,
        {
          [styles[`transparent${capitalizeString(color)}`]]: transparent
            ? styles[`transparent${capitalizeString(color)}`]
            : null
        },
        className
      )}
      onClick={onClick}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {typeof children === `function` ? children({ hovered }) : children}
    </button>
  );
};

export default Button;
