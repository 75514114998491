import { useStaticQuery, graphql } from "gatsby";

const useSanitySettings = () => {
  const { sanitySettings } = useStaticQuery(
    graphql`
      query useSanitySettings {
        sanitySettings {
          # general
          headerBuyLink
          acknowledgement

          # footer
          formHeading
          copyright
          credits

          menuLinks {
            text
            url
          }

          affiliates {
            asset {
              gatsbyImageData
            }
          }

          links {
            text
            url
          }

          socials {
            text
            url
          }
        }
      }
    `
  );

  return {
    sanitySettings
  };
};

export default useSanitySettings;
