import { useSanityPages } from "~hooks";

import links from "~constants/links";

export const useLinks = () => {
  const { getEmptySlugs } = useSanityPages();

  const emptySlugs = getEmptySlugs();

  //

  const getCurrentLink = (pathname) => {
    const index = links.findIndex(
      ({ url }) => url === pathname || `${url}/` === pathname
    );

    const currentLink = links?.[index] || null;

    if (!currentLink) {
      return null;
    }

    return {
      index,
      ...currentLink
    };
  };

  //

  const getPrevLink = (pathname) => {
    const currentLink = JSON.parse(JSON.stringify(getCurrentLink(pathname)));

    if (!currentLink) {
      return null;
    }

    if (currentLink?.index === 0) {
      return {
        category: null,
        label: `Home`,
        navLabel: `Home`,
        url: `/`
      };
    }

    let prevLink;

    const reverseLinks = links.slice().reverse();

    reverseLinks.forEach((link, linkIndex) => {
      const inverseIndex = links.length - linkIndex - 1;

      if (
        prevLink ||
        inverseIndex >= currentLink?.index ||
        !link?.url ||
        emptySlugs.includes(link.url) ||
        emptySlugs.includes(link.url.replace(`/`, ``))
      ) {
        return;
      }

      prevLink = link;
    });

    return prevLink;
  };

  //

  const getNextLink = (pathname) => {
    const currentLink = JSON.parse(JSON.stringify(getCurrentLink(pathname)));

    if (!currentLink || currentLink?.index === links.length - 1) {
      return null;
    }

    let nextLink;

    links.forEach((link, linkIndex) => {
      if (
        nextLink ||
        linkIndex <= currentLink?.index ||
        !link?.url ||
        emptySlugs.includes(link.url) ||
        emptySlugs.includes(link.url.replace(`/`, ``))
      ) {
        return;
      }

      nextLink = link;
    });

    return nextLink;
  };

  return {
    getCurrentLink,
    getPrevLink,
    getNextLink,
    links
  };
};

export default useLinks;
