/* eslint-disable react/jsx-props-no-spreading */

import React, { useRef } from "react";
import cn from "classnames";
import { Grid } from "~components";
import * as styles from "./styles.module.scss";

const Brands = ({ relative = false, inView = true }) => {
  // console.log(`Brands inView: `, inView);

  // ---------------------------------------------------------------------------
  // context / ref / state

  let i;
  // console.log(`ref: `, ref);
  // console.log(`offset: `, offset);
  // console.log(`top: `, top);

  // ---------------------------------------------------------------------------
  // render

  return (
    <section
      className={cn(styles.container, {
        [styles.containerRelative]: relative ? styles.containerRelative : null,
        [styles.containerVisible]: inView ? styles.containerVisible : null
      })}
    >
      <Grid className={cn(styles.text)} node="article">
        <h2 className={cn(styles.heading, `h2`)}>
          We know brand. You know you.
          <br />
          Let’s make something brand new.
        </h2>

        <p className={cn(styles.body, `b2`)}>
          Creating a successful brand is time-consuming and expensive. Genome
          defines your unique brand DNA using best-practice content strategies,
          based on the learnings of market leaders.
        </p>
      </Grid>

      <Grid className={cn(styles.cards)}>
        <article className={cn(styles.card)}>
          <></>
        </article>

        <article className={cn(styles.card)}>
          <></>
        </article>

        <article className={cn(styles.card)}>
          <></>
        </article>
      </Grid>
    </section>
  );
};

export default Brands;
