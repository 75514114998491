import React from "react";
import { useSanitySettings } from "~hooks";
import { Card, Grid } from "~components";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Global header component
 *
 * @return {node}
 */
const CardGrid = ({ data: { backgroundColor, fontColor, heading, cards } }) => {
  const { sanitySettings } = useSanitySettings();
  // const { live } = sanitySettings;

  // if (!live) {
  //   return null;
  // }

  return (
    <section
      className={styles.container}
      style={{
        background: backgroundColor?.hex || `#33FF33`,
        color: fontColor?.hex || `black`
      }}
    >
      <Grid>
        {heading && (
          <div className={styles.gridHeading}>
            <h2 className="b2">{heading}</h2>
          </div>
        )}

        {cards.map((card) => (
          <div key={card._id} className={styles.cardContainer}>
            <Card
              buttonText="VIEW EVENT"
              buttonTarget={`/events/${card.slug.current}`}
              data={{
                ...card,
                subtitle: `${card.datesShort}\n${card.ticketsShort}`
              }}
            />
          </div>
        ))}
      </Grid>
    </section>
  );
};

export default CardGrid;
