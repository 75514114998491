import React, { useEffect, useState } from "react";
import cn from "classnames";
import { useSanityEvents } from "~hooks";
import { Button, Card, Grid } from "~components";

import { ReactComponent as XCircle } from "~assets/svg/x-circle.svg";
import { ReactComponent as ArrowDown } from "~assets/svg/chevron-down.svg";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Filterable Events Grid.
 *
 * @return {node}
 */
const AllEvents = ({ data: { backgroundColor, fontColor } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { allSanityEvent, getAllTags } = useSanityEvents();

  const [activeTags, setActiveTags] = useState([]);
  const [tagSelectExpanded, setTagSelectExpanded] = useState(false);

  const tags = getAllTags();

  // ---------------------------------------------------------------------------
  // methods

  const toggleTag = (tag) => {
    if (!activeTags?.includes(tag)) {
      setActiveTags([...activeTags, tag]);
    } else {
      const index = activeTags.indexOf(tag);

      if (index > -1) {
        const newActiveTags = [...activeTags];

        newActiveTags.splice(index, 1);

        setActiveTags(newActiveTags);
      }
    }
  };

  // ---------------------------------------------------------------------------
  // render

  return (
    <section
      className={styles.container}
      style={{
        background: backgroundColor?.hex || `transparent`,
        color: fontColor?.hex || `black`
      }}
    >
      <Grid className={styles.tags}>
        <div className={styles.tagFlex}>
          {tags?.[0] &&
            tags.map((tag) => (
              <Button
                key={`all-events-button-${tag}`}
                className={styles.tag}
                color="black"
                onClick={() => toggleTag(tag)}
                transparent={!activeTags?.includes(tag)}
              >
                <span className="button-text">{tag}</span>
              </Button>
            ))}
        </div>

        <div className={styles.tagSelect}>
          <button
            type="button"
            className={cn(styles.tagSelectExpand, {
              [styles.tagSelectExpanded]: tagSelectExpanded
                ? styles.tagSelectExpanded
                : null
            })}
            onClick={() => setTagSelectExpanded(!tagSelectExpanded)}
          >
            <span className="button-text">FILTER BY</span>
            <div className={styles.tagSelectArrow}>
              <ArrowDown />
            </div>
          </button>

          <div
            className={cn(styles.tagSelectContent)}
            style={{
              maxHeight: tagSelectExpanded ? `${tags.length * 32}px` : `0`,
              pointerEvents: tagSelectExpanded ? `auto` : `none`
            }}
          >
            {tags?.[0] &&
              tags.map((tag) => (
                <button
                  type="button"
                  key={`all-events-option-${tag}`}
                  className={cn(styles.tagSelectOption, {
                    [styles.tagSelectOptionActive]: activeTags?.includes(tag)
                      ? styles.tagSelectOptionActive
                      : null
                  })}
                  onClick={() => toggleTag(tag)}
                  transparent={!activeTags?.includes(tag)}
                >
                  <span className="button-text">{tag}</span>
                </button>
              ))}
          </div>
        </div>

        <div className={styles.tagClear}>
          <button
            type="button"
            className={styles.tagClearButton}
            onClick={() => {
              setActiveTags([]);
              setTagSelectExpanded(false);
            }}
          >
            <XCircle className={styles.tagClearIcon} />

            <span className="button-text">CLEAR FILTERS</span>
          </button>
        </div>
      </Grid>

      <Grid>
        {allSanityEvent.nodes
          .filter((card) => {
            if (activeTags.length === 0) {
              return true;
            }

            return card.tags.some((tag) => activeTags.includes(tag.title));
          })
          .sort((a, b) => new Date(a.sortableDate) - new Date(b.sortableDate))
          .map((card) => (
            <div key={card._id} className={styles.cardContainer}>
              <Card
                buttonText="VIEW EVENT"
                buttonTarget={`/events/${card.slug.current}`}
                data={{
                  ...card,
                  subtitle: `${card.datesShort}\n${card.ticketsShort}`
                }}
              />
            </div>
          ))}
      </Grid>
    </section>
  );
};

export default AllEvents;
