/* eslint-disable react/button-has-type */

import React, { useRef, useState } from "react";
import cn from "classnames";
import { useExpandable } from "~hooks";
import { GlitchImage } from "~components";

import { ReactComponent as ExpandIcon } from "~assets/svg/expand.svg";
import { ReactComponent as CollapseIcon } from "~assets/svg/collapse.svg";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Card component, used for Events.
 *
 * @param {string} props.buttonTarget
 * @param {string} props.buttonText
 * @param {Object} props.data
 * @param {Object} props.data.cardImage
 * @param {string} props.data.title
 * @param {string} props.data.subtitle
 * @param {Array} props.data.tags
 *
 * @return {JSX.Element} Card component.
 */
const ExpanderCard = ({ data: { image, title, subtitle, body } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const containerRef = useRef();
  const { contentHeight, expanded, setExpanded, expandableRef } =
    useExpandable();

  const [hovering, setHovering] = useState(false);

  // ---------------------------------------------------------------------------
  // render

  return (
    <article
      ref={containerRef}
      className={styles.container}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
    >
      <GlitchImage active={hovering} image={image} />

      <div className={styles.textContent}>
        {body && (
          <div className={styles.expandButtonContainer}>
            <div className={styles.expandButton}>
              <button type="button" onClick={() => setExpanded(!expanded)}>
                <div className={styles.expandButtonIcon}>
                  {expanded ? <CollapseIcon /> : <ExpandIcon />}
                </div>
              </button>
            </div>
          </div>
        )}

        <h3 className="h3">{title}</h3>

        <h4 className={cn(`caption`, styles.subtitle)}>{subtitle}</h4>

        <div
          className={styles.expandableBody}
          style={{ maxHeight: expanded ? contentHeight : 0 }}
          aria-label="Expandable content"
        >
          {body && <p className="b1">{body}</p>}
        </div>

        <div
          ref={expandableRef}
          className={styles.expandableBodyHidden}
          aria-hidden
          aria-label="Expandable card sizer"
        >
          {body && <p className="b1">{body}</p>}
        </div>
      </div>
    </article>
  );
};

export default ExpanderCard;
