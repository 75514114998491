import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { isBrowser } from "~utils/helpers";

/** ============================================================================
 * @component Resize height detector for iOS.
 */
const ViewportHeight = () => {
  const detectHeight = () => {
    if (!isBrowser) {
      return null;
    }

    return window.innerHeight;
  };

  const [height, setHeight] = useState(detectHeight());

  useEffect(() => {
    const updateHeight = () => {
      setHeight(detectHeight());
    };

    window.addEventListener(`resize`, updateHeight);

    return () => window.removeEventListener(`resize`, updateHeight);
  }, []);

  const heightCss = `
    :root{
      --viewport-height: ${height}px;
    }`;

  return (
    <Helmet>
      <style>{heightCss}</style>
    </Helmet>
  );
};

export default ViewportHeight;
