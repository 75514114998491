/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import cn from "classnames";
import { Button, Grid } from "~components";
import * as styles from "./styles.module.scss";

const ScrollText = ({ relative = false, inView = true }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const description = `How do you share what it is that
you do? How do you tell the world about
it in a way that feels like you? Easy.
Start somewhere, anywhere. And keep clicking until you find... you.

Genome uses AI to guide self
expression. We know brand, you know
you - so whatever you do, you're just a
few clicks away...`;

  // ---------------------------------------------------------------------------
  // render

  return (
    <section
      className={cn(styles.container, {
        [styles.containerRelative]: relative ? styles.containerRelative : null,
        [styles.containerVisible]: inView ? styles.containerVisible : null
      })}
    >
      <Grid className={cn(styles.grid)}>
        <article className={cn(styles.content)}>
          <h2>
            <span className={cn(styles.micro, `b2`)}>What is Genome?</span>
            <span className="h1">{description}</span>
          </h2>

          <Button color="lime">
            <span className="button-text-large">TRY GENOME</span>
          </Button>
        </article>
      </Grid>
    </section>
  );
};

export default ScrollText;
