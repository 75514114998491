import React, { useEffect, useRef, useState } from "react";
import { navigate } from "gatsby";
import cn from "classnames";
import {
  useApp,
  useKeyPress,
  useSanitySettings,
  useScroll,
  useWindowDimensions
} from "~hooks";
import { useHeaderStyle } from "~store/useHeaderStore.jsx";
import { Button, Grid, Link } from "~components";
import { useSearchStore } from "~store";
import { capitalizeString } from "~utils/helpers";

import { ReactComponent as ArrowRight } from "~assets/svg/icon-library/arrow-right.svg";
import { ReactComponent as Cross } from "~assets/svg/icon-library/x.svg";
import { ReactComponent as Logo } from "~assets/svg/logo.svg";
import { ReactComponent as Search } from "~assets/svg/icon-library/looking-glass.svg";
import { ReactComponent as Logomark } from "~assets/svg/logomark.svg";

import * as styles from "./styles.module.scss";

/** ============================================================================
 * @component
 * Global header component
 *
 * @return {node}
 */
const Header = () => {
  // ---------------------------------------------------------------------------
  // hooks / store

  const style = useHeaderStyle();
  const { setMenuActive } = useApp();
  const escKeyPressed = useKeyPress(`Escape`);
  const enterKeyPressed = useKeyPress(`Enter`);
  const { scrollTop } = useScroll();
  const { searchTerm, setSearchTerm } = useSearchStore();
  const { isDesktop, screen } = useWindowDimensions();

  const { sanitySettings } = useSanitySettings();

  const { headerBuyLink } = sanitySettings;

  // ---------------------------------------------------------------------------
  // context / ref / state

  const inputRef = useRef();

  const [filled, setFilled] = useState(false);
  const [searchActive, setSearchActive] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const search = () => {
    setSearchActive(false);

    navigate(`/search/`, {
      replace: true
    });
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (
      typeof window !== `undefined` &&
      enterKeyPressed &&
      document.activeElement === inputRef?.current
    ) {
      search();
    }
  }, [inputRef, enterKeyPressed]);

  useEffect(() => {
    if (escKeyPressed && inputRef?.current) {
      setSearchActive(false);
    }
  }, [escKeyPressed]);

  useEffect(() => {
    setFilled(scrollTop > 64);
  }, [scrollTop]);

  useEffect(() => {
    if (!inputRef?.current) {
      return;
    }

    if (searchActive) {
      inputRef.current.focus();
    } else {
      inputRef.current.blur();
    }
  }, [inputRef, searchActive]);

  // ---------------------------------------------------------------------------
  // render

  const capsColor = style ? capitalizeString(style) : ``;

  return (
    <header
      className={cn(styles.container, styles[`container${capsColor}`], {
        [styles[`containerFilled${capsColor}`]]: filled
          ? styles[`containerFilled${capsColor}`]
          : null
      })}
    >
      <Grid className={styles.grid}>
        <div className={cn(styles.logo, styles[`logo${capsColor}`])}>
          <Link to="/">
            {(screen !== `xs` && <Logo className={styles.logoSource} />) || (
              <Logomark className={styles.logoSource} />
            )}
          </Link>
        </div>

        <div className={styles.controls}>
          <>
            {(isDesktop && (
              <div
                className={cn(
                  styles.searchContainer,
                  styles[`searchContainer${capsColor}`],
                  {
                    [styles.searchContainerExpanded]: searchActive
                      ? styles.searchContainerExpanded
                      : null
                  }
                )}
              >
                <button
                  type="button"
                  className={cn(
                    styles.searchIcon,
                    styles.searchIconToggle,
                    styles[`searchIcon${capsColor}`],
                    {
                      [styles.searchIconActive]: searchActive || null
                    }
                  )}
                  onClick={() => setSearchActive(!searchActive)}
                >
                  {searchActive ? <Cross /> : <Search />}
                </button>

                <input
                  autoComplete="off"
                  ref={inputRef}
                  id="header-search-text"
                  name="search"
                  className={cn(
                    styles.searchInput,
                    styles[`searchInput${capsColor}`],
                    `b1`
                  )}
                  onChange={(e) => setSearchTerm(e.currentTarget.value)}
                  placeholder="Search term"
                  value={searchTerm}
                  type="text"
                />

                <button
                  type="button"
                  className={cn(
                    styles.searchIcon,
                    styles.searchIconGo,
                    styles[`searchIcon${capsColor}`],
                    {
                      [styles.searchIconGoActive]:
                        searchActive && searchTerm !== ``
                          ? styles.searchIconGoActive
                          : null
                    }
                  )}
                  onClick={() => search()}
                >
                  <ArrowRight />
                </button>
              </div>
            )) || (
              <Link to="/search/">
                <button
                  type="button"
                  className={cn(
                    styles.searchIcon,
                    styles.searchIconToggle,
                    styles[`searchIcon${capsColor}`],
                    {
                      [styles.searchIconActive]: searchActive || null
                    }
                  )}
                >
                  <Search />
                </button>
              </Link>
            )}
          </>

          {/* {headerBuyLink && (
            <Link to={headerBuyLink}>
              <Button className={styles.tickets} color={style} transparent>
                <span className="button-text">BUY TICKETS</span>
              </Button>
            </Link>
          )} */}

          <button
            type="button"
            className={cn(styles.menu, styles[`menu${capsColor}`])}
            onClick={() => setMenuActive(true)}
          >
            <div className={styles.menuBar} />
            <div className={styles.menuBar} />
            <div className={styles.menuBar} />
          </button>
        </div>
      </Grid>
    </header>
  );
};

export default Header;
