import React from "react";
import { Link as GatsbyLink } from "gatsby";
import cn from "classnames";
import * as styles from "./styles.module.scss";

const Link = ({
  innerRef,
  children,
  activeClassName,
  className,
  disable,
  to,
  title,
  target = `_blank`,
  tabIndex,
  ariaLabel,
  onClick = () => {},
  onMouseOver = () => {},
  onMouseOut = () => {}
}) => {
  const isExternal = to && to.match(/(http|#|tel:|mailto:)/);

  const containsSiteUrl =
    process.env.GATSBY_SITE_URL &&
    to &&
    to.indexOf(process.env.GATSBY_SITE_URL) !== -1;

  if (isExternal && !containsSiteUrl) {
    return (
      <a
        ref={innerRef}
        href={to}
        className={cn(className, styles.link, { [styles.disabled]: disable })}
        title={title || null}
        aria-label={ariaLabel || null}
        target={target}
        onClick={onClick}
        rel="nofollow noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  const withTrailingSlash = to ? `${to}${to.endsWith(`/`) ? `` : `/`}` : false;
  const linkTo = containsSiteUrl
    ? withTrailingSlash.replace(containsSiteUrl, ``)
    : withTrailingSlash;

  return (
    <GatsbyLink
      ref={innerRef || null}
      to={linkTo}
      aria-label={ariaLabel || null}
      activeClassName={activeClassName}
      className={cn(className, styles.link, { [styles.disabled]: disable })}
      title={title || null}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      tabIndex={tabIndex}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;
